.main {
  position: absolute;
  bottom: 20px;
  right: 16px;

  width: 56px;
  height: 56px;
  padding: 12px;

  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 0 6px;

  svg {
    width: 32px;
    height: 32px;
  }
}
