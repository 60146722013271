.softkey {
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;

  border-radius: 4px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 8px;

  background-color: #fff;
  /*box-shadow: rgba(0, 0, 0, 0.12) 0 6px 12px 0;*/

  font-size: 14px;
  line-height: 1.5em;
  color: #005ae0;
}

.softkey > *:first-child {
  margin-right: auto;
}

.softkey > *:last-child {
  margin-left: auto;
}

.softkey > *:only-child,
.softkey > *:nth-child(2):nth-last-child(2) {
  font-weight: 700;
  text-align: center;
  align-self: center;
}

/* Seftkeys from map 
.Softkeys {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;

  display: flex;
  justify-content: space-between;
  padding: 8px;

  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 6px 12px 0;

  font-size: 14px;
  line-height: 1.5em;
  color: #005ae0;
}

.Container[data-update="false"] .Softkeys > div:nth-child(2):nth-last-child(2) {
  display: none;
}
.Softkeys > div:nth-child(2):nth-last-child(2) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  font-size: 16px;
  font-weight: bold;
}

/**/
