.onboarding {
  // Layout
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px;
  // Style
  background-color: #fff;

  p {
    display: flex;
    padding: 16px;
    min-height: 72px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.SolarSystem {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1;

  //Earth
  & > div:first-child {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    width: 208px;
    height: 208px;

    border-radius: 50%;
    background-color: #0071de;
    background-image: url(./asset-world.svg);
    background-position: 0 center;

    animation-name: worldtour;
    animation-duration: 24s;
    animation-delay: 150ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes worldtour {
  from {
    background-position: 0 center;
  }
  to {
    background-position: -480px center;
  }
}
