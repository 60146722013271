.main {
  // Layout
  display: inline-block;
  margin: auto;
  padding: 8px 16px;
  // Style
  border-radius: 8px;
  background-color: rgba(0, 90, 224, 0);
  //Text
  color: #005ae0;
  font-weight: 600; //Should be 700 (bold), but too strong

  &:focus:not([disabled]) {
    background-color: rgba(0, 90, 224, 0.12);
  }

  &[disabled] {
    color: #989898;
  }
  &[disabled]:first-of-type {
    background-color: rgba(152, 152, 152, 0.12);
  }
}
