.here,
.here:before {
  background-color: #1da1f2;
  background-color: rgb(152, 152, 152);
  border-radius: 50%;
}
.here {
  position: relative;
}

/* Pulsation for activated position
.here:before {
	width: 100%;
	height: 100%;
	content: "";
	position: absolute;
	animation: here-pulse 2s infinite;
}
*/
.here:after {
  border-radius: 50%;
  border: 2px solid #fff;
  content: "";
  height: 19px;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 19px;
  box-sizing: border-box;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
}
@keyframes here-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(3);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
