/* Containers */
.Card {
  z-index: 1;
  padding: 8px 8px 44px 8px;

  background-color: #fff;
  border-radius: 8px 8px 0 0;
}

.Card p:only-child {
  text-align: center;
  padding: 16px;
  border-radius: 4px;
  background-color: rgba(199, 199, 199, 0.18);
}

/* Pagging Systemes */

.pagging {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.pagging > * {
  width: 4px;
  height: 4px;
  margin: 0 2px;

  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}

.pagging > *[nav-selected="true"] {
  background-color: rgb(0, 90, 224);
}
