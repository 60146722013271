@import "./reset.css";

html,
body,
#root {
  /* position and layout */
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  /* Text */
  font-family: "SF Pro", "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25em;
  color: rgba(0, 0, 0, 0.9);

  /* Look and feel */
  background-color: rgb(230, 228, 224);
}

#root > div {
  overflow: hidden;
}

i {
  font-style: italic;
}

b {
  font-weight: bold;
}

/** 
* DESKTOP SUPPORT
* The app will be hosted online;
* Users will be able to see it on desktop;
* We define below the behaviour on desktop.
*/

@media (min-width: 360px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(21, 22, 26);
  }

  #root {
    overflow: visible;
    width: 100%;
    height: 100%;
    max-width: 240px;
    max-height: 320px;
  }

  #root:after {
    position: absolute;
    top: 100%;
    z-index: 3;

    margin-top: 12px;

    content: "Made for KaiOS";
    color: rgba(250, 252, 255, 0.4);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  @media (orientation: landscape) and (max-height: 360px) {
    #root {
      max-width: 320px;
      max-height: 240px;
    }
  }
}
