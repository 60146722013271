.Container {
  display: flex;
  align-items: stretch;
  align-content: stretch;

  flex: 1;
  z-index: 0;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 33 33'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='1' height='33' x='16' fill='%23D1CEC7'/%3E%3Crect width='33' height='1' y='16' fill='%23D1CEC7'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
}

.Target {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
